<template>
  <v-sheet
    v-if="display"
    :title="$t('Title')"
    style="position: fixed; inset: 0; z-index: 999999999999999; background-color: transparent"
    @save="buttonAction"
    :style="`padding-bottom: ${ !x || !y ? '210px' : '0' }`"
    :class="{ 'd-flex align-center justify-center' : !x || !y }"
  >
      <div
        class="wsRoundedHalf pa-4"
        :style="`
          width: ${ width };
          height: ${ height };
          background-color: ${ backgroundColor };
          position: ${ x || y ? 'fixed' : 'relative'};
          top: ${bottom ? 'none' : x};
          bottom: ${bottom ? x : 'none'};
          right: ${y};
      `">

        <div v-if="title" class="d-flex align-center justify-space-between mb-4" style="position: relative">
          <h3 :style="`color: ${textColor}; font-size: 13px`">{{ title }}</h3>
          <v-btn
            @click="display = false"
            class="mr-n2 mr-2"
            color="#fff"
            icon
          >
            <v-icon :color="textColor" >mdi-close</v-icon>
          </v-btn>
        </div>

        <div  v-if="description" :class="{ 'd-flex align-start justify-space-between' : !title}" class="mb-4">
          <h5 :style="`color: ${textColor}; line-height: 180%`" class="font-weight-regular">{{ description }}</h5>
          <v-btn
            v-if="!title"
            @click="display = false"
            class="mr-n2 mr-2"
            color="#fff"
            icon
          >
            <v-icon :color="textColor" >mdi-close</v-icon>
          </v-btn>
        </div>

        <slot name="content"></slot>

        <div v-if="action" class="text-right w-100">
          <ws-button :label="buttonLabel" @click="buttonAction"  />
        </div>

        <div
          style="position: absolute;"
          class="d-flex flex-column align-center"
          :style="`${bottom ? `bottom: -40px; right: ${linePosition}` : 'top: -40px; right: 30px'}`"
          :class="{ 'flex-column-reverse' : bottom }"
        >
          <div
            style="height: 10px; width: 10px; border-radius: 50%; padding-right: 5px"
            :style="`background-color: ${backgroundColor}`"
          />
          <div
            style="height: 30px; width: 2px;"
            :style="`background-color: ${backgroundColor}`"
          />
        </div>
      </div>

  </v-sheet>
</template>

<script>
export default {
  name: "wsPromptDialog.vue",
  props : {
    linePosition: {
      type: String,
      default: '50%'
    },
    action: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '300px'
    },
    height: {
      type: String,
      default: 'fit-content'
    },
    value : {
      type : Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: 'Save'
    },
    backgroundColor: {
      type: String,
      default: 'wsBACKGROUND'
    },
    x: {
      type: String,
      default: ''
    },
    y: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: 'wsWHITE'
    },
  },
  data() {
    return {
      display : false
    }
  },
  methods: {
    buttonAction() {
      this.display = false;
      this.$emit('save');
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>
