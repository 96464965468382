var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"d-flex align-center justify-space-between",style:(_vm.position ? 'background-color: transparent; position: absolute; bottom: 8px; left: 32%; z-index: 99999999' : 'background-color: transparent;')},[_vm._l((_vm.mediaSettingsItems),function(mediaItem,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[(!mediaItem.isRound)?_c('div',{staticClass:"pointer rounded-xl mr-4 d-flex align-center justify-space-between",style:(("width: 67px; background-color: " + (!hover ? _vm.wsLIGHTCARD : '#fff') + "; position: relative"))},[_c('ws-tooltip',{attrs:{"top":"","text":mediaItem.tooltip}},[_c('v-icon',{staticClass:"rounded-circle",staticStyle:{"width":"42px","height":"42px","background-color":"#fff"},style:({
                      'background-color': hover ? ("" + _vm.wsBACKGROUND) : '#fff',
                      'opacity': mediaItem.active ? '1' : '0.5'
                    }),on:{"click":function () { return mediaItem.action(); }}},[_vm._v(" "+_vm._s(mediaItem.icon)+_vm._s(!mediaItem.active? '-off' : '')+" ")])],1),_c('ws-tooltip',{attrs:{"text":_vm.$t('webinar.main.preview.check_device'),"top":""}},[_c('ft-select',{attrs:{"items":mediaItem.devices,"disabled":mediaItem.devices && !mediaItem.devices.length},on:{"input":function($event){return _vm.selectDevice($event, mediaItem.value)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var expand = ref.expand;
return [_c('v-icon',[_vm._v(" "+_vm._s('mdi-menu-' + (!expand ? 'down' : 'up'))+" ")])]}}],null,true)})],1),(!_vm.DEVICES_ACCESS_PERMITTED)?_c('v-icon',{staticStyle:{"position":"absolute","top":"0","left":"0"},attrs:{"color":_vm.wsWARNING,"size":"16"},on:{"click":function($event){_vm.displayInstructionsDialog = mediaItem.value}}},[_vm._v(" mdi-alert-circle ")]):_vm._e()],1):_vm._e(),(_vm.displayShareWindow && mediaItem.isRound)?_c('ft-select',{attrs:{"items":_vm.shareButtonSelect}},[_c('v-btn',{staticStyle:{"width":"42px","height":"42px","background-color":"#fff","outline":"none"},style:({
                    'background-color': mediaItem.active ? ("" + _vm.wsDARKER) : '#fff',
                    'color': !mediaItem.active ? ("" + _vm.wsDARKER) : '#fff'
                  }),attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(mediaItem.icon))])],1)],1):_vm._e()],1)]}}],null,true)})}),(_vm.displayInstructionsDialog)?_c('ws-prompt-dialog',{attrs:{"background-color":_vm.wsACCENT,"text-color":_vm.wsWHITETEXT,"title":_vm.browser !== 'other' ? _vm.$t('webinar.main.preview.instructions_title') : _vm.instructionsText,"width":"300px","height":"fit-content","bottom":"","x":"40%","y":_vm.displayInstructionsDialog === 'microphone' ? '44%' : '39.5%'},on:{"input":_vm.closeInstructionsDialog},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('ol',{staticClass:"mb-4",staticStyle:{"font-size":"13px","color":"#fff","padding-inline-start":"15px","line-height":"180%"}},[_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('webinar.main.preview.instructions_step_1')))])]),_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('webinar.main.preview.instructions_step_2')))])])]),(_vm.browserImageName !== 'other')?_c('img',{attrs:{"src":require(("@/modules/webinars/assets/" + _vm.browserImageName + "_settings.png")),"alt":"settings image"}}):_vm._e()])]},proxy:true}],null,false,1112448064),model:{value:(_vm.displayInstructionsDialog),callback:function ($$v) {_vm.displayInstructionsDialog=$$v},expression:"displayInstructionsDialog"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }