<template>
  <v-sheet
      class="d-flex align-center justify-space-between"
      :style="position ? 'background-color: transparent; position: absolute; bottom: 8px; left: 32%; z-index: 99999999' : 'background-color: transparent;'"
  >

    <v-hover v-slot="{ hover }" v-for="(mediaItem, index) in mediaSettingsItems" :key="index">

      <div>
        <div
          v-if="!mediaItem.isRound"
          class="pointer rounded-xl mr-4 d-flex align-center justify-space-between"
          :style="`width: 67px; background-color: ${ !hover ? wsLIGHTCARD : '#fff' }; position: relative`"
        >
          <!--  Mic / Camera Icons    -->
          <ws-tooltip
            top
            :text="mediaItem.tooltip"
          >
            <v-icon
              @click="() => mediaItem.action()"
              class="rounded-circle"
              style="width: 42px; height: 42px; background-color: #fff"
              :style="{
                        'background-color': hover ? `${wsBACKGROUND}` : '#fff',
                        'opacity': mediaItem.active ? '1' : '0.5'
                      }"
            >
              {{ mediaItem.icon }}{{ !mediaItem.active? '-off' : ''}}
            </v-icon>
          </ws-tooltip>

          <!--  Devices lists & Arrow icons   -->
          <ws-tooltip :text="$t('webinar.main.preview.check_device')" top >

            <ft-select
              @input="selectDevice($event, mediaItem.value)"
              :items="mediaItem.devices"
              :disabled="mediaItem.devices && !mediaItem.devices.length"
              #default="{expand}"
            >
              <v-icon>
                {{ 'mdi-menu-' + (!expand ? 'down' : 'up')}}
              </v-icon>
            </ft-select>
          </ws-tooltip>

          <!-- Attention icon (if no access to mic/camera)  -->
          <v-icon
            v-if="!DEVICES_ACCESS_PERMITTED"
            :color="wsWARNING"
            size="16"
            style="position: absolute; top: 0; left: 0"
            @click="displayInstructionsDialog = mediaItem.value"
          >
            mdi-alert-circle
          </v-icon>
        </div>

        <!--   Share button     -->
        <ft-select
          v-if="displayShareWindow && mediaItem.isRound"
          :items="shareButtonSelect"
        >
          <v-btn
            icon
            style="width: 42px; height: 42px; background-color: #fff; outline: none"
            :style="{
                      'background-color': mediaItem.active ? `${wsDARKER}` : '#fff',
                      'color': !mediaItem.active ? `${wsDARKER}` : '#fff'
                    }"
            >
            <v-icon>{{ mediaItem.icon }}</v-icon>
          </v-btn>

        </ft-select>
      </div>

    </v-hover>

    <!--  Prompt Dialog (Instructions for turning on media devices) -->
    <ws-prompt-dialog
      v-if="displayInstructionsDialog"
      v-model="displayInstructionsDialog"
      :background-color="wsACCENT"
      :text-color="wsWHITETEXT"
      :title="browser !== 'other' ? $t('webinar.main.preview.instructions_title') : instructionsText"
      width="300px"
      height="fit-content"
      @input="closeInstructionsDialog"
      bottom
      x="40%"
      :y="displayInstructionsDialog === 'microphone' ? '44%' : '39.5%'"
    >
      <template #content>
        <div >
          <ol style="font-size: 13px; color: #fff; padding-inline-start: 15px; line-height: 180%" class="mb-4">
            <li>
              <span >{{ $t('webinar.main.preview.instructions_step_1') }}</span>
            </li>
            <li>
              <span>{{ $t('webinar.main.preview.instructions_step_2') }}</span>
            </li>
          </ol>
          <img v-if="browserImageName !== 'other'" :src="require(`@/modules/webinars/assets/${browserImageName}_settings.png`)" alt="settings image">
        </div>
      </template>
    </ws-prompt-dialog>


  </v-sheet>
</template>

<script>
import WsPromptDialog from "@modules/webinars/components/UI/wsPromptDialog.vue";
import webinars from "@modules/webinars/mixins/webinars";

export default {
  name: "webinarMediaSelector",
  mixins:[webinars],
  components: {
    WsPromptDialog

  },
  props : {
    position: {
      type: Boolean,
      default: false
    },
    displayShareWindow : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      displayInstructionsDialog: '',
      browser: null,
      browserInstructionsImages: ['chrome', 'safari', 'edge'],
    }
  },
  computed : {
    mediaSettingsItems() {
      return [
        {
          icon: 'mdi-microphone',
          active: this.MICROPHONE_IS_ON,
          value: 'microphone',
          tooltip: this.$t('webinar.main.preview.microphone'),
          isRound: false,
          devices: this.MICROPHONES_SELECT,
          action: () => { this.$store.state.stream.microphoneOn = !this.$store.state.stream.microphoneOn }
        },
        {
          icon: 'mdi-video',
          active: this.VIDEO_IS_ON,
          value: 'camera',
          tooltip: this.$t('webinar.main.preview.camera'),
          isRound: false,
          devices: this.CAMERAS_SELECT,
          action: () => { this.$store.state.stream.videoOn = !this.$store.state.stream.videoOn }
        },
        {
          icon: 'mdi-share-circle',
          active: this.isActiveShareButton,
          value: 'share',
          tooltip: this.$t('webinar.main.preview.share'),
          isRound: true,
          // action: () => { this.isActiveShareButton = !this.isActiveShareButton }
        }
      ]
    },
    shareButtonSelect() {
      return [
        {
          text: this.$t('webinar.main.preview.stop_sharing'),
          value: 'stop-sharing',
          icon: 'mdi-share-off',
          action: () => {}
        },
        {
          text: this.$t('webinar.main.preview.share_another_screen'),
          value: 'share-another-screen',
          icon: 'mdi-monitor-share',
          action: () => {}
        },
        {
          text: this.$t('webinar.main.preview.change_main_screen'),
          value: 'change-main-screen',
          icon: 'mdi-sync',
          action: () => {}
        },
      ]
    },

    browserImageName() {
      return this.browserInstructionsImages.includes(this.browser) ? this.browser : 'other'
    },
    instructionsText() {
      let message = `${this.$t('webinar.main.preview.blocked_access')} `;
      if (!this.videoPermission && !this.audioPermission) {
        message += `${this.$t('webinar.main.preview.mic_and_cam')}. `
      }

      if (!this.videoPermission && this.audioPermission) {
        message += `${this.$t('webinar.main.preview.cam')}. `
      }

      if (!this.audioPermission && this.videoPermission) {
        message += `${this.$t('webinar.main.preview.mic')}. `
      }

      return message += `${this.$t('webinar.main.preview.refresh_settings')}.`;
    },
  },
  watch : {
    DEVICES_ACCESS_PERMITTED() {
      this.initDevices()
    }
  },
  methods : {
    initDevices() {
      if (this.DEVICES_ACCESS_PERMITTED
          && this.CAMERAS_SELECT.length
          && this.MICROPHONES_SELECT.length
          && !this.SELECTED_CAMERA
          && !this.SELECTED_MICROPHONE
      ) {
        this.$store.state.stream.videoDeviceId = this.CAMERAS_SELECT[0].value
        this.$store.state.stream.audioDeviceId = this.MICROPHONES_SELECT[0].value
      }
    },
    selectDevice(value , type) {
      if (type === 'microphone') {
        this.$store.state.stream.audioDeviceId = value
      } else if (type === 'camera') {
        this.$store.state.stream.videoDeviceId = value
      }
    },
    detectBrowser() {
      const userAgent = window.navigator.userAgent;

      switch (true) {
        case /Edg|edg/i.test(userAgent): this.browser = 'edge'
          break;
        case /safari/i.test(userAgent) && !/chrome|chromium|crios/i.test(userAgent): this.browser = 'safari'
          break;
        case /chrome|chromium|crios/i.test(userAgent): this.browser = 'chrome';
          break;
        default: this.browser = 'other';

      }
    },
    closeInstructionsDialog(value) {
      if (!value) {
        this.displayInstructionsDialog = ''
      }
    },
  },
  mounted() {
    this.initDevices();
    this.detectBrowser()
  }
}
</script>



<style scoped>

</style>
